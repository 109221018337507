/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase, max-len, react/jsx-closing-tag-location, react/self-closing-comp */

import React from 'react'

const IllustrationDentist = ({ width }) => {
  width ||= 525.122

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="272.222"
      viewBox="0 0 525.122 272.222"
      style={{
        maxWidth: '640px',
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        position: 'relative',
      }}
    >
      <defs>
        <style>
          {`

      .a {
        fill: white;
      }

      .b {
        fill: #f4bc2b;
      }

      .c {
        fill: #f2c768;
      }

      .d {
        fill: #b0d2f5;
      }

      .e {
        fill: #2a3967;
      }

      .f {
        fill: var(--color-secondary-lightest);
      }

      .g {
        fill: #ffa8a7;
      }

      .h {
        fill: #6d4650;
      }

      .i {
        fill: var(--color-base);
      }

      .j {
        fill: var(--color-primary-dark);
      }

      .k {
        fill: #fc7600;
      }

      .l {
        fill: #b57900;
      }

      .m {
        fill: #a9c2ff;
      }

      .n {
        fill: #b8d0fc;
      }

      .o {
        fill: #a1c2f2;
      }

      .p {
        fill: var(--color-secondary);
      }

      .q {
        fill: #ffae73;
      }

      .r {
        fill: var(--color-primary-light);
      }

      .s {
        fill: var(--color-secondary-dark);
      }

      .t {
        fill: #6b0000;
      }

      .u {
        fill: #cbd9f0;
      }

      .v {
        fill: #ffa8a7;
      }

      .w {
        fill: #daa382;
      }

      .x {
        fill: #ffa8a7;
      }

      .y {
        fill: #3e3d64;
      }`}
        </style>
      </defs>
      <g transform="translate(-60.858 -4.688)">
        <rect
          className="b"
          width="24.051"
          height="24.458"
          rx="9.43"
          transform="translate(197.206 34.366)"
        ></rect>
        <rect
          className="c"
          width="21.966"
          height="22.343"
          rx="8.61"
          transform="translate(198.252 35.431)"
        ></rect>
        <path
          className="d"
          d="M280.12,46.941a.5.5,0,1,1,.5.521.51.51,0,0,1-.5-.521Z"
          transform="translate(-71.386 -9.278)"
        ></path>
        <path
          className="d"
          d="M285.522,47.917a.487.487,0,0,1,.876-.03.543.543,0,0,1-.008.535.484.484,0,0,1-.664.181.534.534,0,0,1-.2-.686Z"
          transform="translate(-72.767 -9.546)"
        ></path>
        <path
          className="d"
          d="M290.1,51.7a.484.484,0,0,1,.686.157.536.536,0,0,1-.148.719.483.483,0,0,1-.687-.153.544.544,0,0,1,.148-.723Z"
          transform="translate(-73.904 -10.432)"
        ></path>
        <path
          className="d"
          d="M292.307,57.43a.5.5,0,0,1,.471.321.548.548,0,0,1-.1.579.488.488,0,0,1-.551.118.547.547,0,0,1-.17-.863A.486.486,0,0,1,292.307,57.43Z"
          transform="translate(-74.403 -11.725)"
        ></path>
        <ellipse
          className="d"
          cx="0.497"
          cy="0.521"
          rx="0.497"
          ry="0.521"
          transform="translate(216.183 50.705)"
        ></ellipse>
        <path
          className="d"
          d="M286.794,68.247a.539.539,0,0,1,0,.736.481.481,0,0,1-.7,0,.539.539,0,0,1,0-.736A.488.488,0,0,1,286.794,68.247Z"
          transform="translate(-72.889 -14.097)"
        ></path>
        <path
          className="d"
          d="M280.12,70.321a.5.5,0,1,1,.5.521A.51.51,0,0,1,280.12,70.321Z"
          transform="translate(-71.386 -14.475)"
        ></path>
        <path
          className="d"
          d="M275.68,47.93a.484.484,0,0,0-.664-.181.534.534,0,0,0-.2.686.485.485,0,0,0,.675.2.509.509,0,0,0,.239-.311.535.535,0,0,0-.046-.4Z"
          transform="translate(-70.002 -9.559)"
        ></path>
        <path
          className="d"
          d="M271.114,51.7a.484.484,0,0,0-.686.157.536.536,0,0,0,.148.719.483.483,0,0,0,.687-.153A.545.545,0,0,0,271.114,51.7Z"
          transform="translate(-68.863 -10.432)"
        ></path>
        <path
          className="d"
          d="M268.9,57.43a.5.5,0,0,0-.471.321.548.548,0,0,0,.1.579.488.488,0,0,0,.551.118.547.547,0,0,0,.17-.863A.486.486,0,0,0,268.9,57.43Z"
          transform="translate(-68.357 -11.725)"
        ></path>
        <ellipse
          className="d"
          cx="0.497"
          cy="0.521"
          rx="0.497"
          ry="0.521"
          transform="translate(201.272 50.705)"
        ></ellipse>
        <path
          className="d"
          d="M274.42,68.247a.539.539,0,0,0,0,.736.481.481,0,0,0,.7,0,.539.539,0,0,0,0-.736.488.488,0,0,0-.7,0Z"
          transform="translate(-69.877 -14.097)"
        ></path>
        <path
          className="d"
          d="M280.61,57.961a.5.5,0,1,1,.5.521.509.509,0,0,1-.5-.521Z"
          transform="translate(-71.512 -11.728)"
        ></path>
        <rect
          className="d"
          width="0.134"
          height="6.525"
          transform="translate(209.528 39.709)"
        ></rect>
        <path
          className="d"
          d="M.01.1,4.278,0l-.01.42L0,.519Z"
          transform="matrix(0.787, -0.617, 0.617, 0.787, 209.333, 45.976)"
        ></path>
        <path
          className="e"
          d="M491.368,234.91a1.575,1.575,0,0,1-1.1-.614c-1.246-1.377-2.122-5.444-2.6-12.077-.364-4.931-.356-9.519-.356-9.566h.252c0,.179,0,18.283,2.893,21.456.43.474.823.622,1.2.467,2.159-.91,3.531-11.393,3.427-26.076h.26c.1,15.025-1.3,25.36-3.591,26.325a.963.963,0,0,1-.386.086Z"
          transform="translate(-124.873 -45.311)"
        ></path>
        <path
          className="e"
          d="M484.965,234.56a1.56,1.56,0,0,1-1.1-.614c-1.246-1.369-2.122-5.444-2.6-12.077-.363-4.931-.363-9.519-.363-9.566h.26c0,.179,0,18.283,2.893,21.456.43.474.823.622,1.2.467,2.151-.91,3.531-11.393,3.427-26.076h.26c.1,15.025-1.306,25.36-3.591,26.325a.991.991,0,0,1-.386.085Z"
          transform="translate(-123.218 -45.233)"
        ></path>
        <path
          className="e"
          d="M497.258,234.56a1.561,1.561,0,0,1-1.09-.614c-1.246-1.369-2.122-5.444-2.611-12.077-.356-4.931-.356-9.519-.356-9.566h.26c0,.179,0,18.283,2.893,21.456.43.474.823.622,1.2.467,2.151-.91,3.531-11.393,3.427-26.076h.23c.1,15.025-1.306,25.36-3.591,26.325a1,1,0,0,1-.364.085Z"
          transform="translate(-126.394 -45.233)"
        ></path>
        <path
          className="e"
          d="M478.588,234.56a1.575,1.575,0,0,1-1.1-.614c-1.239-1.369-2.122-5.444-2.6-12.077-.356-4.931-.356-9.519-.356-9.566h.26c0,.179,0,18.283,2.886,21.456.438.474.831.622,1.2.467,2.159-.91,3.531-11.393,3.427-26.076h.26c.111,15.025-1.3,25.36-3.591,26.325a.963.963,0,0,1-.386.085Z"
          transform="translate(-121.574 -45.233)"
        ></path>
        <rect
          className="f"
          width="20.423"
          height="3.196"
          transform="translate(333.29 152.862)"
        ></rect>
        <rect
          className="c"
          width="45.942"
          height="64.081"
          rx="14.46"
          transform="translate(289.202 149.074)"
        ></rect>
        <path
          className="g"
          d="M311.562,175.874a8.3,8.3,0,0,0,4.147-4.075c.72-1.866,1.1-2.279-.579-2.613-.742-.148-3.709-3.033-4.392-3.772a54.921,54.921,0,0,0-4.184-3.974l-11.395.249c1.4.373,4.659,7.862,5.319,12.109C301.05,177.5,308.3,177.53,311.562,175.874Z"
          transform="translate(-75.268 -34.848)"
        ></path>
        <path
          className="h"
          d="M311.567,165.188c-1.714-2.979-2.812-4.728-2.812-4.728l-10.386,3.546C301.241,166.176,305.084,167.622,311.567,165.188Z"
          transform="translate(-76.097 -34.631)"
        ></path>
        <path
          className="g"
          d="M309.642,156.969s-12.47,6.688-16.321-1.516-8.064-12.871-.26-17.49,10.824-2.286,12.745.124S312.787,155.017,309.642,156.969Z"
          transform="translate(-73.593 -29.04)"
        ></path>
        <path
          className="i"
          d="M293.023,151.432s-2.315-1.275-1.662-2.776,1.773.086,2.552-.241,1.484-3.243.9-5.5,8.108-1.415,10.861-3.5,4.139-7.287-1.372-8c-3.234-.42-19.481-2.737-22.671,6.183-4.073,11.362,7.938,17.84,9.6,18.874s2.226.381,2.226.381S294.67,153.08,293.023,151.432Z"
          transform="translate(-71.559 -28.044)"
        ></path>
        <path
          className="j"
          d="M362,217.867s56.588-3.811,63.517,1.96,36.432,41.031,36.432,41.031l-10.1,4.9s-31.506-29.319-33.709-29.552-61.054,3.764-67.723,1.921A41.21,41.21,0,0,1,339,232.978Z"
          transform="translate(-86.586 -47.119)"
        ></path>
        <path
          className="c"
          d="M357.469,213.738c-.326.6-.69,1.213-1.083,1.82a38.79,38.79,0,0,1-6.276,7.38,27.813,27.813,0,0,1-5.571,4.044,9.412,9.412,0,0,1-5.935,1.338c-24.08-4.6-45.334-38.456-45.334-38.456s2.856-12.793,7.908-14.4a89.7,89.7,0,0,1,12.4-2.613s7.129-2.333,13.828,5.506c4.918,5.747,25.408,21.721,30.749,27.017h0a4.985,4.985,0,0,1,1.276,1.555C359.62,208.815,358.849,211.218,357.469,213.738Z"
          transform="translate(-74.78 -37.312)"
        ></path>
        <path
          className="j"
          d="M491.6,276.5s4.206,7.108,5.616,6.447c.185-.086,6.892-3.888,6.892-3.888l8.561-4.892a3.1,3.1,0,0,0-3.9-1.182c-2.737.949-7.864,1.47-11.128-1.135C494.122,269.126,491.6,276.5,491.6,276.5Z"
          transform="translate(-125.98 -59.26)"
        ></path>
        <path
          className="b"
          d="M314.284,170.348c-.274,0-11.869-.645-13.279.506s-1.595,7-1.595,7l20.467-5.755A10.881,10.881,0,0,0,314.284,170.348Z"
          transform="translate(-76.365 -36.789)"
        ></path>
        <path
          className="k"
          d="M365.478,227.4a38.788,38.788,0,0,1-6.276,7.38c-16.8.957-30.245-8.741-30.712-9.006Z"
          transform="translate(-83.873 -49.15)"
        ></path>
        <path
          className="b"
          d="M366.856,219.891l-26.706-3.725L329.63,200.2c.23.194,16.358,13.819,37.916,11.323h0a4.987,4.987,0,0,1,1.276,1.555C369.007,214.968,368.235,217.371,366.856,219.891Z"
          transform="translate(-84.167 -43.465)"
        ></path>
        <path
          className="l"
          d="M405.18,326.224H381.871L361.73,272.75h23.3Z"
          transform="translate(-92.454 -59.594)"
        ></path>
        <path
          className="b"
          d="M333.76,251.36s3.709,28.619,32.315,30.462,38.39-22.11,38.39-28.673Z"
          transform="translate(-85.233 -54.839)"
        ></path>
        <path
          className="m"
          d="M321.15,353.352s1.847-3.935,6.024-4.161,40.319,0,40.319,0v3.888Z"
          transform="translate(-81.978 -76.566)"
        ></path>
        <path
          className="b"
          d="M431.191,345.195s-3.709-28.619-32.315-30.462-39.637,23.89-39.637,30.462Z"
          transform="translate(-91.811 -68.907)"
        ></path>
        <path
          className="f"
          d="M496.882,191.9H467.638l-8.8,8.065h29.028Z"
          transform="translate(-117.523 -41.62)"
        ></path>
        <rect
          className="n"
          width="29.021"
          height="4.837"
          transform="translate(341.317 158.344)"
        ></rect>
        <path
          className="o"
          d="M506.983,191.9v12.91H497.97v-4.845Z"
          transform="translate(-127.625 -41.62)"
        ></path>
        <path
          className="f"
          d="M419.761,171.1l-3.079-.98V109.745a7.991,7.991,0,0,0-7.789-8.158H372.7a7.669,7.669,0,0,0-5.5,2.387l-10.423,10.888-2.181-2.279L365.02,101.7A10.52,10.52,0,0,1,372.7,98.36h36.195a11.147,11.147,0,0,1,10.868,11.385Z"
          transform="translate(-90.61 -20.825)"
        ></path>
        <path
          className="f"
          d="M361.788,123.175c-1.8,2.193-7.025.591-11.684-3.593s-6.973-9.332-5.193-11.549a10.758,10.758,0,0,1,15.172-.679,12.064,12.064,0,0,1,1.69,15.82Z"
          transform="translate(-87.957 -22.201)"
        ></path>
        <path
          className="p"
          d="M361.788,123.888c-1.8,2.193-7.025.591-11.684-3.593s-6.973-9.332-5.193-11.549,7.025-.583,11.677,3.593S363.583,121.687,361.788,123.888Z"
          transform="translate(-87.957 -22.914)"
        ></path>
        <path
          className="q"
          d="M384.678,224.155a17.322,17.322,0,0,1,5.453,2.963s1.892,1.283-.43,1.859c-1.55.389-2.226-.156-4.4.35s-4.451-.086-5.371-1.555l-1.58-1.05.935-3.212S384.819,223.969,384.678,224.155Z"
          transform="translate(-96.744 -48.648)"
        ></path>
        <path
          className="c"
          d="M303.541,182.4S282.9,195.825,324.127,218.02c6.595,3.546,35.3.879,35.3.879s.49-3.6-1.484-4.915c0,0-23.991-6.33-26.224-5.685-1.973.575-14.481-19.193-19.006-22.872a12.943,12.943,0,0,0-9.169-3.025Z"
          transform="translate(-76.097 -39.502)"
        ></path>
        <path
          className="r"
          d="M327.614,235.853a2.572,2.572,0,0,1-2.492-2.278,2.658,2.658,0,0,1,1.787-2.916c.868-.272,21.588-6.47,49.266-1.719a2.64,2.64,0,0,1,2.089,3.045,2.546,2.546,0,0,1-2.9,2.189c-26.558-4.565-46.833,1.508-47.033,1.555A2.516,2.516,0,0,1,327.614,235.853Z"
          transform="translate(-82.997 -49.457)"
        ></path>
        <path
          className="r"
          d="M436.959,273a6.721,6.721,0,0,1-4.451-1.726l-41.862-37.243-72.53,4.021a6.837,6.837,0,0,1-5.809-2.761l-48.791-65.007a7.5,7.5,0,0,1,1.392-9.858,6.7,6.7,0,0,1,9.469.875l46.565,62.044,71.692-3.966a6.736,6.736,0,0,1,4.852,1.719l43.954,39.11a7.485,7.485,0,0,1,2.029,8.023,6.947,6.947,0,0,1-6.48,4.77Z"
          transform="translate(-66.778 -34.276)"
        ></path>
        <path
          className="n"
          d="M479.745,201.42a1.641,1.641,0,0,1-1.484,1.05.807.807,0,0,1-.875-1.05,1.649,1.649,0,0,1,1.484-1.05A.811.811,0,0,1,479.745,201.42Z"
          transform="translate(-122.301 -43.503)"
        ></path>
        <path
          className="n"
          d="M486.165,201.42a1.641,1.641,0,0,1-1.484,1.05.8.8,0,0,1-.875-1.05,1.649,1.649,0,0,1,1.484-1.05A.821.821,0,0,1,486.165,201.42Z"
          transform="translate(-123.958 -43.503)"
        ></path>
        <path
          className="n"
          d="M492.164,201.42a1.619,1.619,0,0,1-1.484,1.05c-.66,0-1.053-.467-.875-1.05a1.649,1.649,0,0,1,1.484-1.05C491.971,200.37,492.364,200.86,492.164,201.42Z"
          transform="translate(-125.506 -43.503)"
        ></path>
        <path
          className="n"
          d="M473.394,201.42a1.641,1.641,0,0,1-1.484,1.05c-.66,0-1.053-.467-.875-1.05a1.641,1.641,0,0,1,1.484-1.05A.808.808,0,0,1,473.394,201.42Z"
          transform="translate(-120.66 -43.503)"
        ></path>
        <path
          className="e"
          d="M470.73,192.078a.135.135,0,0,1-.134-.117l-.638-3.756a.129.129,0,0,1,.018-.1.118.118,0,0,1,.086-.053.121.121,0,0,1,.1.022.133.133,0,0,1,.052.087l.645,3.756a.137.137,0,0,1-.021.1.125.125,0,0,1-.083.055Z"
          transform="translate(-120.393 -40.764)"
        ></path>
        <path
          className="e"
          d="M473.52,209.037h0a.746.746,0,0,1-.861-.63l-2.6-15.258a.776.776,0,0,1,.593-.9h0a.746.746,0,0,1,.861.63l2.611,15.258A.775.775,0,0,1,473.52,209.037Z"
          transform="translate(-120.416 -41.694)"
        ></path>
        <path
          className="e"
          d="M476.457,191.7a.136.136,0,0,1-.126-.117l-.645-3.756a.864.864,0,0,0-.5-.661l-1.209-.544a.15.15,0,0,1-.074-.179.131.131,0,0,1,.171-.07l1.217.544a1.134,1.134,0,0,1,.66.863l.638,3.756a.129.129,0,0,1-.018.1.118.118,0,0,1-.086.053Z"
          transform="translate(-121.409 -40.39)"
        ></path>
        <path
          className="e"
          d="M479.868,209.037h0a.746.746,0,0,1-.86-.63L476.4,193.149a.775.775,0,0,1,.6-.9h0a.746.746,0,0,1,.86.63l2.611,15.258A.775.775,0,0,1,479.868,209.037Z"
          transform="translate(-122.053 -41.694)"
        ></path>
        <path
          className="e"
          d="M483.633,192.8a.136.136,0,0,1-.126-.117l-.208-1.221a.14.14,0,0,1,.1-.156.134.134,0,0,1,.148.109l.208,1.221a.13.13,0,0,1-.018.1.118.118,0,0,1-.086.053Z"
          transform="translate(-123.837 -41.489)"
        ></path>
        <path
          className="e"
          d="M486.268,209.037h0a.746.746,0,0,1-.861-.63L482.8,193.149a.775.775,0,0,1,.6-.9h0a.746.746,0,0,1,.861.63l2.611,15.258A.775.775,0,0,1,486.268,209.037Z"
          transform="translate(-123.705 -41.694)"
        ></path>
        <path
          className="e"
          d="M489.514,192.8a.136.136,0,0,1-.126-.117l-.208-1.221a.147.147,0,0,1,.1-.156.122.122,0,0,1,.1.022.133.133,0,0,1,.052.087l.215,1.221a.137.137,0,0,1-.021.1.125.125,0,0,1-.083.055Z"
          transform="translate(-125.356 -41.488)"
        ></path>
        <path
          className="e"
          d="M492.15,209.037h0a.746.746,0,0,1-.861-.63l-2.6-15.258a.776.776,0,0,1,.593-.9h0a.746.746,0,0,1,.861.63l2.611,15.258A.775.775,0,0,1,492.15,209.037Z"
          transform="translate(-125.225 -41.694)"
        ></path>
        <path
          className="f"
          d="M423.9,174.353h-.519v-16.4a2.082,2.082,0,0,0-2.04-2.108h-2.107a2.2,2.2,0,0,0-2.151,2.248v2.178h-.512v-2.178a2.73,2.73,0,0,1,2.663-2.792h2.107a2.623,2.623,0,0,1,2.552,2.675Z"
          transform="translate(-106.611 -33.483)"
        ></path>
        <path
          className="f"
          d="M390.75,179.53a16.826,16.826,0,0,0,31.3,0Z"
          transform="translate(-99.945 -38.87)"
        ></path>
        <path
          className="l"
          d="M612.73,263.255v1.781c0,.63.193,1.135.423,1.135h62.129c.23,0,.423-.506.423-1.135v-1.781c0-.622-.193-1.135-.423-1.135H613.153C612.93,262.12,612.73,262.633,612.73,263.255Z"
          transform="translate(-157.251 -57.231)"
        ></path>
        <path
          className="b"
          d="M612.73,263.255v1.781c0,.63.171,1.135.393,1.135h49.318c.215,0,.393-.506.393-1.135v-1.781c0-.622-.178-1.135-.393-1.135H613.123C612.93,262.12,612.73,262.633,612.73,263.255Z"
          transform="translate(-157.251 -57.231)"
        ></path>
        <rect
          className="b"
          width="44.103"
          height="67.394"
          transform="translate(515.301 276.335) rotate(180)"
        ></rect>
        <rect
          className="c"
          width="44.103"
          height="67.394"
          transform="translate(502.942 276.335) rotate(180)"
        ></rect>
        <path
          className="f"
          d="M620.45,269.78h39.37v61.771H620.45Zm38.939.443H620.858V331.1h38.509Z"
          transform="translate(-159.244 -58.934)"
        ></path>
        <rect
          className="f"
          width="38.939"
          height="0.443"
          transform="translate(461.421 231.283)"
        ></rect>
        <rect
          className="f"
          width="38.939"
          height="0.443"
          transform="translate(461.421 252.11)"
        ></rect>
        <path
          className="f"
          d="M641.388,274.52h7.226a.654.654,0,0,0,.638-.669h0a.654.654,0,0,0-.638-.661h-7.226a.616.616,0,0,0-.451.191.677.677,0,0,0-.187.47h0a.677.677,0,0,0,.185.475.616.616,0,0,0,.453.194Z"
          transform="translate(-164.485 -59.692)"
        ></path>
        <path
          className="f"
          d="M641.388,300.718h7.226a.662.662,0,0,0,.638-.669h0a.662.662,0,0,0-.638-.669h-7.226a.654.654,0,0,0-.638.669h0A.654.654,0,0,0,641.388,300.718Z"
          transform="translate(-164.485 -65.515)"
        ></path>
        <path
          className="f"
          d="M641.388,328.018h7.226a.654.654,0,0,0,.638-.669h0a.662.662,0,0,0-.638-.669h-7.226a.654.654,0,0,0-.638.669h0a.677.677,0,0,0,.185.475.616.616,0,0,0,.453.194Z"
          transform="translate(-164.485 -71.584)"
        ></path>
        <path
          className="s"
          d="M659.334,224.314s-4.132-5.055-.252-8.267c0,0,2.826-1.8,5.141-1.649,0,0-3.279,5.755-2.226,6.323s3.272-6.68,3.272-6.68,6.447-.661,8.516.389c0,0-5.5,4.728-4.347,5.008s7.114-4.363,7.114-4.363,5.994,1.073,5.675,4.907-2.389,5.024-3.294,4.923-6.224-2.232-6.335-1.454,3.079,3.336,5.319,3.453c0,0-4.229,4.884-7.04,3.476s-3.049-3.647-4.8-4.487-3.249-.832-2.522,0,3.895,1.688,4.9,3.538,1.9,3.111-1.009,2.652-7.633-2.683-7.841-5.242Z"
          transform="translate(-168.788 -46.497)"
        ></path>
        <path
          className="s"
          d="M678.656,221.862s-16.321-.653-19.889,4.231a12.364,12.364,0,0,1-3.242,4.775l-.386,1.089s2.826-3.111,3.835-3.313C658.975,228.643,657.4,222.593,678.656,221.862Z"
          transform="translate(-168.2 -48.275)"
        ></path>
        <path
          className="s"
          d="M654.369,233.317a10.861,10.861,0,0,0-3.709,7.551l.63.062s1.12-5.008,3.138-7S654.369,233.317,654.369,233.317Z"
          transform="translate(-167.043 -50.725)"
        ></path>
        <path
          className="p"
          d="M632.169,214.04s6.573-3.888,3.5-9.1c0,0-2.456-3.212-5.082-4.036,0,0,1.4,7.777,0,7.987s-.964-8.819-.964-8.819-6.921-3.492-9.644-3.212c0,0,4.3,7.621,2.893,7.442s-6.224-7.9-6.224-7.9-7.1-1.376-8.234,3.033.7,6.61,1.751,6.89,7.8.179,7.619,1.1-4.725,2.387-7.27,1.555c0,0,2.8,7.256,6.484,6.89s4.822-2.753,7.1-2.94,3.947.459,2.8,1.1-4.993.21-6.832,1.851-3.331,2.652.082,3.383,9.555.28,10.779-2.481Z"
          transform="translate(-156.045 -42.576)"
        ></path>
        <path
          className="s"
          d="M612.92,202.13s18.4,6.221,20.5,13.221a15.19,15.19,0,0,0,1.751,6.7v1.376s-1.929-4.666-2.967-5.327C632.193,218.111,636.31,212.053,612.92,202.13Z"
          transform="translate(-157.3 -43.894)"
        ></path>
        <path
          className="s"
          d="M642.609,227.515a13.269,13.269,0,0,1,1.217,10.017l-.742-.2s.7-6.058-.779-9.107S642.609,227.515,642.609,227.515Z"
          transform="translate(-164.734 -49.347)"
        ></path>
        <path
          className="p"
          d="M652.668,205.565s-8.665-5.086-4.622-11.992c0,0,3.234-4.238,6.677-5.327,0,0-1.847,10.289,0,10.538S656,187.118,656,187.118s9.125-4.6,12.7-4.238c0,0-5.653,10.048-3.806,9.807s8.2-10.413,8.2-10.413,9.355-1.82,10.861,4-.927,8.718-2.315,9.083-10.274.241-10.044,1.446,6.239,3.111,9.585,2.061c0,0-3.709,9.566-8.546,9.083s-6.358-3.632-9.355-3.888-5.193.607-3.709,1.454,6.588.272,9.013,2.434,4.392,3.5-.119,4.464-12.611.366-14.206-3.266Z"
          transform="translate(-166.091 -39.42)"
        ></path>
        <path
          className="s"
          d="M680.88,189.87s-24.258,8.236-27.033,17.443a19.981,19.981,0,0,1-2.307,8.834v1.82s2.537-6.175,3.924-7C655.464,210.969,650.034,202.951,680.88,189.87Z"
          transform="translate(-167.27 -41.169)"
        ></path>
        <path
          className="s"
          d="M650.76,223.339a17.459,17.459,0,0,0-1.61,13.221l.964-.272s-.935-7.979,1.024-12S650.76,223.339,650.76,223.339Z"
          transform="translate(-166.49 -48.36)"
        ></path>
        <path
          className="s"
          d="M648.353,255.778c9.365,0,16.958-7.96,16.966-17.778H631.38c.008,9.821,7.6,17.778,16.973,17.778Z"
          transform="translate(-162.066 -51.869)"
        ></path>
        <path
          className="t"
          d="M665.477,261.373H631.07a2.144,2.144,0,0,1,2.092-2.193h30.223A2.144,2.144,0,0,1,665.477,261.373Z"
          transform="translate(-161.986 -56.577)"
        ></path>
        <rect
          className="t"
          width="34.881"
          height="0.544"
          transform="translate(468.758 186.116)"
        ></rect>
        <rect
          className="s"
          width="1.098"
          height="42.345"
          transform="translate(178.749 230.972)"
        ></rect>
        <rect
          className="s"
          width="1.098"
          height="42.345"
          transform="translate(223.356 230.972)"
        ></rect>
        <rect
          className="s"
          width="45.712"
          height="29.723"
          transform="translate(178.749 202.299)"
        ></rect>
        <rect
          className="s"
          width="45.712"
          height="29.723"
          transform="translate(163.92 202.299)"
        ></rect>
        <rect
          className="p"
          width="44.607"
          height="13.788"
          transform="translate(179.298 202.851)"
        ></rect>
        <rect
          className="p"
          width="44.607"
          height="14.162"
          transform="translate(179.298 217.254)"
        ></rect>
        <rect
          className="s"
          width="45.482"
          height="0.754"
          transform="translate(178.749 246.713)"
        ></rect>
        <path
          className="i"
          d="M236.27,349.247a2.882,2.882,0,1,1,2.878,3.017A2.95,2.95,0,0,1,236.27,349.247Z"
          transform="translate(-60.065 -75.93)"
        ></path>
        <ellipse
          className="u"
          cx="1.691"
          cy="1.773"
          rx="1.691"
          ry="1.773"
          transform="translate(177.392 271.544)"
        ></ellipse>
        <rect
          className="s"
          width="1.098"
          height="42.345"
          transform="translate(164.157 230.972)"
        ></rect>
        <rect
          className="s"
          width="45.482"
          height="0.754"
          transform="translate(164.157 246.713)"
        ></rect>
        <ellipse
          className="i"
          cx="2.878"
          cy="3.017"
          rx="2.878"
          ry="3.017"
          transform="translate(161.605 270.3)"
        ></ellipse>
        <ellipse
          className="u"
          cx="1.691"
          cy="1.773"
          rx="1.691"
          ry="1.773"
          transform="translate(162.792 271.544)"
        ></ellipse>
        <path
          className="i"
          d="M296.39,349.247a2.881,2.881,0,1,1,2.871,3.017A2.95,2.95,0,0,1,296.39,349.247Z"
          transform="translate(-75.586 -75.93)"
        ></path>
        <ellipse
          className="u"
          cx="1.691"
          cy="1.773"
          rx="1.691"
          ry="1.773"
          transform="translate(221.984 271.544)"
        ></ellipse>
        <path
          className="f"
          d="M272.236,268.529h-5.69a.6.6,0,0,1-.586-.614h0a.6.6,0,0,1,.586-.614h5.69a.6.6,0,0,1,.586.614h0A.6.6,0,0,1,272.236,268.529Z"
          transform="translate(-67.73 -58.383)"
        ></path>
        <path
          className="f"
          d="M272.236,287.819h-5.69a.6.6,0,0,1-.586-.614h0a.6.6,0,0,1,.586-.614h5.69a.6.6,0,0,1,.586.614h0A.6.6,0,0,1,272.236,287.819Z"
          transform="translate(-67.73 -62.671)"
        ></path>
        <path
          className="p"
          d="M516.387,158.391l-22.159,4.542L483.39,126.242l22.159-4.542Z"
          transform="translate(-123.861 -26.014)"
        ></path>
        <path
          className="a"
          d="M509.281,143.5v-.047l-.067-.241v-.062l-.156-.552v-.07a.875.875,0,0,0-.059-.218v-.062l-.082-.272h0l-.074-.241v-.078l-.067-.2V141.4l-.082-.257V141.1q-.042-.1-.074-.2v-.078l-.067-.187v-.054l-.089-.233v-.062a1.674,1.674,0,0,1-.059-.179v-.07l-.074-.187v-.039l-.089-.21v-.062l-.067-.156v-.062l-.089-.2h0a1.512,1.512,0,0,1-.082-.194v-.054l-.067-.14v-.054l-.082-.179h0c0-.054-.052-.109-.082-.156v-.047l-.067-.132h0l-.082-.156h0l-.067-.124h0l-.067-.117h0l-.082-.124h0a.483.483,0,0,0-.059-.093h0l-.067-.093h0a.4.4,0,0,0-.074-.086h0l-.052-.062h0l-.067-.062a1.9,1.9,0,0,0-2.582-.14c-.089.062-.178.124-.26.194a2.15,2.15,0,0,0-.289.233l-.215.179-.119.109a1.584,1.584,0,0,0-.171.148,6.258,6.258,0,0,1-.742.583,2.232,2.232,0,0,1-.816.342h-.423l-.3-.047h-.148c-.423-.1-.964-.272-1.8-.436l-.438-.07c-1.944-.226-2.5.778-2.6,1.742h0a3.42,3.42,0,0,0,0,.614c.66,9.76,5.386,14.387,6.424,14.208,1.283-.226.742-5.156,1.439-5.864.957-.957,1.8-.327,2.775,1.3s2.663,5.522,3.709,1.369a9.444,9.444,0,0,0,.185-2.675h0V148.7h0a1.236,1.236,0,0,0,0-.163v-.054a1.523,1.523,0,0,0-.037-.4h0v-.109h0l-.067-.49v-.443a3.687,3.687,0,0,0-.082-.552v-.047a2.7,2.7,0,0,0-.1-.544h0v-.078h0a1.886,1.886,0,0,0-.082-.4v-.1l-.067-.311h0a1.754,1.754,0,0,0-.082-.381v-.047l-.089-.373h0C509.4,143.924,509.326,143.7,509.281,143.5Z"
          transform="translate(-127.163 -29.34)"
        ></path>
        <path
          className="w"
          d="M497,144.121a.137.137,0,0,1,.016-.114.125.125,0,0,1,.1-.057.19.19,0,0,1,.163.171.137.137,0,0,1-.016.114.125.125,0,0,1-.1.057A.19.19,0,0,1,497,144.121Z"
          transform="translate(-127.373 -30.96)"
        ></path>
        <path
          className="w"
          d="M497.329,143.046a.073.073,0,0,1,.011-.057.066.066,0,0,1,.048-.029.1.1,0,0,1,.082.086c0,.047,0,.086-.059.086A.1.1,0,0,1,497.329,143.046Z"
          transform="translate(-127.459 -30.74)"
        ></path>
        <path
          className="w"
          d="M498.26,144.376c0-.047,0-.086.052-.086a.1.1,0,0,1,.082.086c0,.047,0,.086-.052.086a.1.1,0,0,1-.082-.086Z"
          transform="translate(-127.7 -31.036)"
        ></path>
        <path
          className="x"
          d="M497.358,154.1a2.172,2.172,0,0,0-.549-1,15.991,15.991,0,0,1-1.113-2.854c-.1-1.338-.972-.871-1.105.3,0,.249.119.879.119,1.143-1.4-.591-5.556-4.254-5.66-1.431-.052,1.485,1.424,4.993,1.78,5.638.6,1.081,4.036,2.185,5.8,2.419l2.1.171,1.217-3.546Z"
          transform="translate(-125.322 -32.13)"
        ></path>
        <path
          className="i"
          d="M579.481,340.707s-.556,9.332-2.285,9.5h-8.769l-10.9-.062a3.663,3.663,0,0,1,3.257-3.336c3.183-.583,8.516-2.916,10.445-7.326C573.264,334.82,579.481,340.707,579.481,340.707Z"
          transform="translate(-143.001 -74.031)"
        ></path>
        <path
          className="j"
          d="M575.128,309.372a21.8,21.8,0,0,1-5.935,1.159,20.682,20.682,0,0,1-5.5-.824s-.623-6.323-4.058-20.22c-1.8-7.3-4.37-16.689-8.027-28.362-10.111-32.243-13.183-51.109-13.464-52.883v-.14h0l31.076-14.3s-3.843,44.445,1.061,61.5a74.255,74.255,0,0,1,1.78,8.174C575.173,281.935,575.128,309.372,575.128,309.372Z"
          transform="translate(-137.995 -42.043)"
        ></path>
        <path
          className="j"
          d="M572.081,263.387,559.64,289.432c-1.8-7.3-4.369-16.689-8.027-28.362-10.111-32.243-13.183-51.109-13.464-52.883a1.236,1.236,0,0,1,0-.156l31.076-14.3s-3.843,44.445,1.061,61.5a74.242,74.242,0,0,1,1.8,8.158Z"
          transform="translate(-137.997 -42.027)"
        ></path>
        <path
          className="i"
          d="M546.754,340.7s-.549,9.332-2.277,9.5h-8.769l-10.9-.062a3.653,3.653,0,0,1,3.257-3.336c3.19-.583,8.516-2.932,10.445-7.349C540.53,334.817,546.754,340.7,546.754,340.7Z"
          transform="translate(-134.554 -74.028)"
        ></path>
        <path
          className="j"
          d="M530.64,195.06a486.721,486.721,0,0,0,4.347,64.159c4.755,35.361,3.145,50.371,3.145,50.371a30.656,30.656,0,0,0,4.585,1.221,19.915,19.915,0,0,0,6.8-.824s6.8-35.377,4.852-53.077,9.318-60.729,9.318-60.729Z"
          transform="translate(-136.059 -42.323)"
        ></path>
        <path
          className="x"
          d="M556.277,103.166l-5.126,5.4-8.9.2c0-.778-.059-3.888-.089-6.719V97.061l7.27-5.444,2.715-2.038c0,.054.282,2.629.326,3a22.507,22.507,0,0,0,3.806,10.584Z"
          transform="translate(-139.033 -18.873)"
        ></path>
        <path
          className="r"
          d="M528.2,176.347c.126,15.2,1.091,50.759,1.8,51.483,7.552,7.645,40.8-2.092,42.537-10.514s-6.677-107.958-19.6-111.932c-2.678-.824-16.988,4.021-16.988,4.021a99.5,99.5,0,0,0-3.709,14c-.542,2.909-2.322,23.642-3.368,37.492-.267,3.694-.5,6.9-.63,9.146C528.181,170.973,528.174,173.228,528.2,176.347Z"
          transform="translate(-135.425 -22.365)"
        ></path>
        <path
          className="x"
          d="M551.846,93.3c-1.142,3.515-7.159,7.652-9.7,9.247V97.554l7.27-5.444C551.156,91.675,552.313,91.854,551.846,93.3Z"
          transform="translate(-139.03 -19.389)"
        ></path>
        <path
          className="x"
          d="M536.69,92.494s13.746,1.392,14.444-7.6,2.967-14.862-5.809-16.137S534.6,70.781,533.67,73.721,533.129,91.9,536.69,92.494Z"
          transform="translate(-136.73 -14.181)"
        ></path>
        <path
          className="y"
          d="M533.607,71.34s2.782,9.674,4.644,8.819,3.561-1.555,3.865,1.478-1.706,7.054,4.392,6.221,11.484-18.867,9.221-21.573C549.215,58.54,524.586,62.07,533.607,71.34Z"
          transform="translate(-136.318 -12.749)"
        ></path>
        <path
          className="j"
          d="M542.159,104.476c.171-.078,9.822-4.612,10.95-4.223s2.53,4.005,2.53,4.005l-16.988,4.021S539.993,105.433,542.159,104.476Z"
          transform="translate(-138.127 -21.24)"
        ></path>
        <path
          className="j"
          d="M528.2,181.465c31.521-22.382,28.932-53.155,28.932-53.155-12.292,7.271-24.481,24.489-28.279,37.7-.267,3.694-.5,6.9-.631,9.146C528.181,176.091,528.173,178.346,528.2,181.465Z"
          transform="translate(-135.425 -27.483)"
        ></path>
        <path
          className="r"
          d="M536.929,117.949S528.042,150.3,524.421,150.3c-2.4,0-23.405-3.647-23.405-3.647l-1.936,4.775s23.405,16.168,31.9,12.91c13.472-5.156,21.6-30.182,18.635-38.705S542.611,114.271,536.929,117.949Z"
          transform="translate(-127.912 -24.829)"
        ></path>
        <rect
          className="p"
          width="59.837"
          height="40.782"
          transform="translate(122.006 53.637)"
        ></rect>
        <path
          className="a"
          d="M182.167,103.437c-.6,0-1.484-.653-2.263-1.75a16.07,16.07,0,0,1-2.685-6.875,28.8,28.8,0,0,1,.668-11.759,4.741,4.741,0,0,1,.3-.832c.653-1.376,2.226-2.776,5.853-1.633.935.288,1.61.552,2.159.778a3.852,3.852,0,0,0,3.294.226,13.5,13.5,0,0,0,1.662-.7c1.944-.91,4.362-2.045,6.172,0h0c1.484,1.633,2.329,10.39.675,16.479a12.356,12.356,0,0,1-1.484,3.538c-1.1,1.664-2.04,2.411-2.878,2.286-1.231-.179-1.855-2.146-2.411-3.888-.126-.381-.237-.778-.356-1.058-.519-1.439-1.083-2.24-1.766-2.52a2.311,2.311,0,0,0-1.973.117,11.109,11.109,0,0,0-1.662,3.212c-.927,2.154-1.884,4.386-3.257,4.386Zm-.742-22.615c-4.644,1-4.852,15.787-1.254,20.64.89,1.2,1.632,1.618,2,1.618h0c1.15,0,2.1-2.224,2.967-4.176a9.419,9.419,0,0,1,1.884-3.212,2.332,2.332,0,0,1,2.181-.3c.786.319,1.409,1.182,1.966,2.722.119.327.237.692.364,1.073.5,1.555,1.231,3.111,2.226,3.29.69.1,1.365-.49,2.374-2.006a10.322,10.322,0,0,0,1.484-3.212c1.6-5.872.779-14.6-.586-16.129-1.654-1.851-3.85-.824-5.786.086a14.252,14.252,0,0,1-1.7.723,4.167,4.167,0,0,1-3.516-.241C185.535,81.5,183.361,80.41,181.425,80.822Z"
          transform="translate(-44.738 -16.687)"
        ></path>
        <path
          className="f"
          d="M199.724,84.133l-.074-.1L205.941,79H218.73v.124H205.978Z"
          transform="translate(-50.612 -16.521)"
        ></path>
        <path
          className="f"
          d="M218.73,104.895H205.941l-.022-.008-6.269-5.024.074-.093,6.254,5.008H218.73Z"
          transform="translate(-50.612 -21.138)"
        ></path>
        <rect
          className="f"
          width="19.421"
          height="0.117"
          transform="translate(149.075 73.157)"
        ></rect>
        <path
          className="f"
          d="M223.012,89.643h-5.727a.221.221,0,0,1-.215-.226h0a.22.22,0,0,1,.215-.218h5.727a.22.22,0,0,1,.215.218h0a.22.22,0,0,1-.215.226Z"
          transform="translate(-55.109 -18.788)"
        ></path>
        <path
          className="f"
          d="M223.012,90.766h-5.727a.2.2,0,0,1-.152-.061.223.223,0,0,1-.063-.157h0a.22.22,0,0,1,.215-.218h5.727a.22.22,0,0,1,.215.218h0a.223.223,0,0,1-.063.157A.2.2,0,0,1,223.012,90.766Z"
          transform="translate(-55.109 -19.04)"
        ></path>
        <path
          className="f"
          d="M223.012,76.783h-5.727a.22.22,0,0,1-.215-.218h0a.221.221,0,0,1,.215-.226h5.727a.22.22,0,0,1,.215.226h0a.22.22,0,0,1-.215.218Z"
          transform="translate(-55.109 -15.929)"
        ></path>
        <path
          className="f"
          d="M223.012,77.876h-5.727a.2.2,0,0,1-.152-.061.223.223,0,0,1-.063-.157h0a.22.22,0,0,1,.215-.218h5.727a.22.22,0,0,1,.215.218h0a.223.223,0,0,1-.063.157A.2.2,0,0,1,223.012,77.876Z"
          transform="translate(-55.109 -16.174)"
        ></path>
        <path
          className="f"
          d="M223.012,103.873h-5.727a.22.22,0,0,1-.215-.218h0a.221.221,0,0,1,.215-.226h5.727a.221.221,0,0,1,.215.226h0a.22.22,0,0,1-.215.218Z"
          transform="translate(-55.109 -21.952)"
        ></path>
        <path
          className="f"
          d="M223.012,104.963h-5.727a.221.221,0,0,1-.215-.226h0a.22.22,0,0,1,.215-.218h5.727a.22.22,0,0,1,.215.218h0a.221.221,0,0,1-.215.226Z"
          transform="translate(-55.109 -22.194)"
        ></path>
        <path
          className="f"
          d="M223.824,109.294h-62.2V66h62.2Zm-59.844-2.481h57.478v-38.3H163.979Z"
          transform="translate(-40.794 -13.631)"
        ></path>
        <path
          className="c"
          d="M276.295,242.937v10.732H266V242.9a2.079,2.079,0,0,1,0-.3,3.288,3.288,0,0,1,1.907-2.737,2.179,2.179,0,0,0,1.306-1.882l.074-1.555h3.791v1.555a2.129,2.129,0,0,0,1.269,1.9,3.3,3.3,0,0,1,1.921,2.73A2.571,2.571,0,0,1,276.295,242.937Z"
          transform="translate(-67.738 -51.518)"
        ></path>
        <path
          className="c"
          d="M274.351,236.953H269.93l.111-1.213h4.191Z"
          transform="translate(-68.755 -51.366)"
        ></path>
        <path
          className="f"
          d="M276.295,244.7v6.921H266v-6.96a2.079,2.079,0,0,1,0-.3h10.289a2.582,2.582,0,0,1,.007.342Z"
          transform="translate(-67.738 -53.283)"
        ></path>
        <path
          className="c"
          d="M289.3,249.828v6h-5.772v-6.027a1.379,1.379,0,0,1,0-.171,1.831,1.831,0,0,1,1.068-1.555,1.217,1.217,0,0,0,.742-1.058l.045-.871h2.122v.871a1.186,1.186,0,0,0,.742,1.058,1.854,1.854,0,0,1,1.076,1.555,1.709,1.709,0,0,1-.022.194Z"
          transform="translate(-72.265 -53.681)"
        ></path>
        <path
          className="c"
          d="M288.22,246.494h-2.47l.059-.684h2.344Z"
          transform="translate(-72.839 -53.605)"
        ></path>
        <path
          className="f"
          d="M289.3,250.831v3.888h-5.772v-3.888a1.38,1.38,0,0,1,0-.171h5.757a1.7,1.7,0,0,1,.015.171Z"
          transform="translate(-72.265 -54.683)"
        ></path>
      </g>
    </svg>
  )
}

export default IllustrationDentist
