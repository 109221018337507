/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'

const iconDefault = css`
  fill: none;
  stroke: white;
  stroke-width: 2;
  transition: stroke 0.2s;
  cursor: pointer;

  :hover {
    stroke: #ffa503;
  }
`

const logoDark = css`
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--color-base);
    margin-left: 8px;
    transition: color 0.125s ease;
    user-select: none;
  }

  svg {
    stroke: var(--color-base);
  }

  .tooth {
    fill: var(--color-primary);
  }

  @media (hover: hover) {
    :hover {
      svg {
        stroke: var(--color-secondary);
      }
      span {
        color: var(--color-secondary);
      }
      .tooth {
        fill: var(--color-secondary);
      }
    }
  }

  :active {
    svg {
      stroke: var(--color-secondary-dark);
    }
    span {
      color: var(--color-secondary-dark);
    }
    .tooth {
      fill: var(--color-secondary-dark);
    }
  }
`

const logoLight = css`
  ${logoDark}
  span {
    color: white;
  }
  svg {
    stroke: white;
  }
  .tooth {
    fill: #ffffff;
  }
`

const IconDodoDentist = props => {
  const { width = 32, height = 32, light, onClick } = props
  const dataTestId = props['data-testid'] || 'icon-globo'

  return (
    <span css={light ? logoLight : logoDark}>
      <svg
        css={iconDefault}
        width={width}
        height={height}
        onClick={onClick}
        data-testid={dataTestId}
        viewBox="0 -960 960 960"
      >
        <path
          className="tooth"
          d="M680-875q66 0 113 47t47 113q0 11-1.5 29.5T834-643l-55 403q-5 38-34.5 62T677-154q-23 0-42.5-10T602-192L495-348q-2-4-6.5-5.5T479-355q-4 0-16 9L359-195q-14 20-34.5 30.5T281-154q-38 0-67-24.5T180-241l-54-402q-3-24-4.5-42.5T120-715q0-66 47-113t113-47q36 0 57.5 9.5T379-845q20 11 42.5 20.5T480-815q36 0 58.5-9.5T581-845q20-11 42-20.5t57-9.5Zm0 80q-23 0-40.5 9.5T601-765q-21 11-49 20.5t-72 9.5q-44 0-72-9.5T359-765q-21-11-38.5-20.5T280-795q-33 0-56.5 23.5T200-715q0 8 1 23t4 35l55 405q1 8 7 12.5t14 4.5q5 0 9-2t6-6l101-148q14-20 36-32t47-12q25 0 47 12t36 32l103 151q2 3 5 4.5t7 1.5q8 0 14.5-4.5T700-251l55-406q3-20 4-35t1-23q0-33-23.5-56.5T680-795ZM480-515Z"
        />
      </svg>
      <span>Dodo Dentist</span>
    </span>
  )
}

export default IconDodoDentist
